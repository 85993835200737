<template>
  <LoginLayout>
    <v-container fill-height fluid>
      <v-row align="center" justify="center">
        <v-col>
          <form @submit.prevent="submit">
            <h2 class="text-center my-4">{{ pageTitle }}</h2>
            <v-card max-width="300" class="mx-auto">
              <v-card-text>
                <div class="p-2">
                  <v-text-field
                    v-model="email"
                    type="email"
                    :error-messages="emailErrors"
                    label="Masukkan Email"
                    required
                    @input="$v.email.$touch()"
                    @blur="$v.email.$touch()"
                  ></v-text-field>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  class="mr-4"
                  @click="submit"
                  block
                  :color="$store.state.appThemeColor"
                  dark
                >
                  Kirim
                </v-btn>
              </v-card-actions>
              <v-card-actions>
                <v-btn text link :to="{ name: 'Login' }" block>Login</v-btn>
              </v-card-actions>
            </v-card>
          </form>
        </v-col>
      </v-row>
    </v-container>

    <template>
      <v-dialog v-model="dialog" persistent max-width="300px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Konfirmasi</span>
          </v-card-title>
          <v-card-text>
            <v-container class="pa-0">
              <p>Berhasil mengirim Kode konfirmasi, silahkan cek Email Anda.</p>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    outlined
                    label="Kode Konfirmasi"
                    :counter="6"
                    type="number"
                    required
                    v-model="confirmationCode"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" dark block @click="confirm">
              Konfirmasi
            </v-btn>
          </v-card-actions>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              class="mr-4"
              @click="submit"
              block
              :color="$store.state.appThemeColor"
              dark
              outlined
            >
              Kirim Ulang Kode
            </v-btn>
          </v-card-actions>
          <v-card-actions>
            <v-btn text link :to="{ name: 'Login' }" block>Batal</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template>
      <v-dialog v-model="dialog2" persistent max-width="300px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Ubah Password</span>
          </v-card-title>
          <v-card-text>
            <v-container class="px-0">
              <v-row class="py-2">
                <v-col cols="12" class="pa-0">
                  <v-text-field
                    outlined
                    label="Ketik Password Baru"
                    required
                    type="password"
                    v-model="password"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row class="py-2">
                <v-col cols="12" class="pa-0">
                  <v-text-field
                    outlined
                    label="Ketik Ulang Password Baru"
                    required
                    type="password"
                    v-model="password2"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-btn color="blue darken-1" dark block @click="changePassword"> Ubah Password </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </LoginLayout>
</template>

<script>
import LoginLayout from "@/layouts/LoginLayout.vue";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "Forget",
  components: { LoginLayout },
  mixins: [validationMixin],

  validations: {
    email: { required, email },
  },

  data: () => ({
    dialog: null,
    dialog2: null,
    email: "",
    pageTitle: "Lupa Password",
    confirmationCode: "",
    password: null,
    password2: null,
  }),

  computed: {
    emailErrors() {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.required && errors.push("Email tidak boleh kosong.");
      !this.$v.email.email && errors.push("Email tidak valid.");
      return errors;
    },
  },

  methods: {
    submit() {
      this.$v.$touch();
      this.confirmationCode = "";

      if (!this.$v.$invalid) {
        let formData = new FormData();
        formData.append("email", this.email);

        this.$store.state.showLoadingDialog = true;
        this.$axios
          .post("forgot/request", formData)
          .then((res) => {
            this.$store.state.showLoadingDialog = false;
            if (res.data.status == "success") {
              this.dialog = true;
            }else{
              this.showAlert("error", res.data.message);
            }
          })
          .catch((error) => {
            console.log(error);
            this.showAlert("error", "Internal Server Error!");
            this.$store.state.showLoadingDialog = false;
          })
          .finally(() => (this.$store.state.showLoadingDialog = false));
      }
    },
    confirm() {
      let formData = new FormData();
      formData.append("email", this.email);
      formData.append("code", this.confirmationCode);

      this.$store.state.showLoadingDialog = true;
      this.$axios
        .post("forgot/verify", formData)
        .then((res) => {
          this.$store.state.showLoadingDialog = false;
          if (res.data.status == "success") {
            this.dialog = false;
            this.dialog2 = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.showAlert("error", "Internal Server Error!");
          this.$store.state.showLoadingDialog = false;
        })
        .finally(() => (this.$store.state.showLoadingDialog = false));
    },
    changePassword() {
      let formData = new FormData();
      formData.append("email", this.email);
      formData.append("code", this.confirmationCode);
      formData.append("password", this.password);
      formData.append("password2", this.password2);

      this.$store.state.showLoadingDialog = true;
      this.$axios
        .post("forgot/change_password", formData)
        .then((res) => {
          this.$store.state.showLoadingDialog = false;
          if (res.data.status == "success") {
            alert(res.data.message);
            this.$router.push("/login");
          }else{
            this.showAlert("error", res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
          this.showAlert("error", "Internal Server Error!");
          this.$store.state.showLoadingDialog = false;
        })
        .finally(() => (this.$store.state.showLoadingDialog = false));
    },
    clear() {
      this.$v.$reset();
      this.email = "";
      this.confirmationCode = "";
      this.password = "";
      this.password2 = "";
    },
  },
};
</script>

<style scoped>
.bg1 {
  background: #0F2027;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #2C5364, #203A43, #0F2027);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #2C5364, #203A43, #0F2027); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}
h2 {
  color: #ffffff;
}
</style>